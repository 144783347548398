import {createAction, props} from '@ngrx/store';
import {
  Category, Episode, PaginatedMusic, PaginatedPodcast, PaginatedSkits,
  PaginatedUser, Tracks,
  User, Ads, AdsAudience, PaginatedAds, Plan, PlanList
} from "@frontend/data-models";

const AdminActions = {
  GET_ADMIN_DASHBOARD: '[ADMIN] Dashboard',
  GET_ADMIN_DASHBOARD_SUCCESS: '[ADMIN] Dashboard Success',
  GET_ADMIN_DASHBOARD_ERROR: '[ADMIN] Dashboard Error',

  GET_ARTIST_PROFILE: '[ADMIN] Artist Profile',
  GET_ARTIST_PROFILE_SUCCESS: '[ADMIN] Artist Profile Success',
  GET_ARTIST_PROFILE_ERROR: '[ADMIN] Artist Profile Error',

  GET_ALL_ARTISTS: '[ADMIN] Get all Artists',
  GET_ALL_ARTISTS_SUCCESS: '[ADMIN] Get all Artists Success',
  GET_ALL_ARTISTS_ERROR: '[ADMIN] Get all Artists Error',

  UPDATE_ARTIST: '[ADMIN] Update Artist',
  UPDATE_ARTIST_SUCCESS: '[ADMIN] Update Artist Success',
  UPDATE_ARTIST_ERROR: '[ADMIN] Update Artist Error',

  ACCEPT_REJECT_ARTIST_PROFILE: '[ADMIN] Accept Reject Artist Profile',
  ACCEPT_REJECT_ARTIST_PROFILE_SUCCESS: '[ADMIN] Accept Reject Artist Profile Success',
  ACCEPT_REJECT_ARTIST_PROFILE_ERROR: '[ADMIN] Accept Reject Artist Profile Error',

  GET_ALL_RADIO_STATION: '[ADMIN] Get all Radio Stations',
  GET_ALL_RADIO_STATION_SUCCESS: '[ADMIN] Get all Radio Stations Success',
  GET_ALL_RADIO_STATION_ERROR: '[ADMIN] Get all Radio Stations Error',

  GET_RADIO_STATION_BY_ID: '[ADMIN] Get Radio station by id',
  GET_RADIO_STATION_BY_ID_SUCCESS: '[ADMIN] Get Radio station by id Success',
  GET_RADIO_STATION_BY_ID_ERROR: '[ADMIN] Get Radio station by id Error',

  ADD_UPDATE_RADIO_STATION: '[ADMIN] Add / Update Radio Station',
  ADD_UPDATE_RADIO_STATION_SUCCESS: '[ADMIN] Add / Update Radio Station Success',
  ADD_UPDATE_RADIO_STATION_ERROR: '[ADMIN] Add / Update Radio Station Error',

  DELETE_RADIO_STATION: '[ADMIN] Delete Radio Station',
  DELETE_RADIO_STATION_SUCCESS: '[ADMIN] Delete Radio Station Success',
  DELETE_RADIO_STATION_ERROR: '[ADMIN] Delete Radio Station Error',

  GET_ALL_GENRES: '[ADMIN] Get all Genre',
  GET_ALL_GENRES_SUCCESS: '[ADMIN] Get all Genre Success',
  GET_ALL_GENRES_ERROR: '[ADMIN] Get all Genre Error',

  ADD_UPDATE_GENRE: '[ADMIN] Add / Update Genre',
  ADD_UPDATE_GENRE_SUCCESS: '[ADMIN] Add / Update Genre Success',
  ADD_UPDATE_GENRE_ERROR: '[ADMIN] Add / Update Genre Error',

  DELETE_GENRE: '[ADMIN] Delete Genre',
  DELETE_GENRE_SUCCESS: '[ADMIN] Delete Genre Success',
  DELETE_GENRE_ERROR: '[ADMIN] Delete Genre Error',

  GET_ALL_CATEGORY: '[ADMIN] Get All Category',
  GET_ALL_CATEGORY_SUCCESS: '[ADMIN] Get All Category Success',
  GET_ALL_CATEGORY_ERROR: '[ADMIN] Get All Category Error',

  ADD_UPDATE_CATEGORY: '[ADMIN] Add / Update Category',
  ADD_UPDATE_CATEGORY_SUCCESS: '[ADMIN] Add / Update Category Success',
  ADD_UPDATE_CATEGORY_ERROR: '[ADMIN] Add / Update Category Error',

  DELETE_CATEGORY: '[ADMIN] Delete Category',
  DELETE_CATEGORY_SUCCESS: '[ADMIN] Delete Category Success',
  DELETE_CATEGORY_ERROR: '[ADMIN] Delete Category Error',

  GET_MUSIC_LIST: '[ADMIN] Get Music List',
  GET_MUSIC_LIST_SUCCESS: '[ADMIN] Get Music List Success',
  GET_MUSIC_LIST_ERROR: '[ADMIN] Get Music List Error',

  GET_MUSIC_TRACK_LIST: '[ARTIST] Get Music Track List',
  GET_MUSIC_TRACK_LIST_SUCCESS: '[ARTIST] Get Music Track List Success',
  GET_MUSIC_TRACK_LIST_ERROR: '[ARTIST] Get Music Track List Error',

  GET_PODCAST_LIST: '[ARTIST] Get Podcast List',
  GET_PODCAST_LIST_SUCCESS: '[ARTIST] Get Podcast List Success',
  GET_PODCAST_LIST_ERROR: '[ARTIST] Get Podcast List Error',

  GET_PODCAST_EPISODE_LIST: '[ARTIST] Get Podcast Episode List',
  GET_PODCAST_EPISODE_LIST_SUCCESS: '[ARTIST] Get Podcast Episode List Success',
  GET_PODCAST_EPISODE_LIST_ERROR: '[ARTIST] Get Podcast Episode List Error',

  GET_SKITS_LIST: '[ARTIST] Get Skits List',
  GET_SKITS_LIST_SUCCESS: '[ARTIST] Get Skits List Success',
  GET_SKITS_LIST_ERROR: '[ARTIST] Get Skits List Error',

  GET_ADS: '[ADMIN] Get Ads',
  GET_ADS_SUCCESS: '[ADMIN] Get Ads Success',
  GET_ADS_ERROR: '[ADMIN] Get Ads Error',

  GET_ADS_AUDIENCE: '[ADMIN] Get Ads Audience',
  GET_ADS_AUDIENCE_SUCCESS: '[ADMIN] Get Ads Audience Success',
  GET_ADS_AUDIENCE_ERROR: '[ADMIN] Get Ads Audience Error',

  GET_AD: '[ADMIN] Get Ad',
  GET_AD_SUCCESS: '[ADMIN] Get Ad Success',
  GET_AD_ERROR: '[ADMIN] Get Ad Error',

  GET_AD_AUDIENCE: '[ADMIN] Get Ad Audience',
  GET_AD_AUDIENCE_SUCCESS: '[ADMIN] Get Ad Audience Success',
  GET_AD_AUDIENCE_ERROR: '[ADMIN] Get Ad Audience Error',

  CREATE_AD_AUDIENCE: '[ADMIN] Create Ad Audience',
  CREATE_AD_AUDIENCE_SUCCESS: '[ADMIN] Create Ad Audience Success',
  CREATE_AD_AUDIENCE_ERROR: '[ADMIN] Create Ad Audience Error',

  CREATE_AD: '[ADMIN] Create Ad',
  CREATE_AD_SUCCESS: '[ADMIN] Create Ad Success',
  CREATE_AD_ERROR: '[ADMIN] Create Ad Error',

  UPDATE_AD: '[ADMIN] Update Ad',
  UPDATE_AD_SUCCESS: '[ADMIN] Update Ad Success',
  UPDATE_AD_ERROR: '[ADMIN] Update Ad Error',

  UPDATE_AD_AUDIENCE: '[ADMIN] Update Ad Audience',
  UPDATE_AD_AUDIENCE_SUCCESS: '[ADMIN] Update Ad Audience Success',
  UPDATE_AD_AUDIENCE_ERROR: '[ADMIN] Update Ad Audience Error',

  DELETE_AD_AUDIENCE: '[ADMIN] Delete Ad Audience',
  DELETE_AD_AUDIENCE_SUCCESS: '[ADMIN] Delete Ad Audience Success',
  DELETE_AD_AUDIENCE_ERROR: '[ADMIN] Delete Ad Audience Error',

  DELETE_AD: '[ADMIN] Delete Ad',
  DELETE_AD_SUCCESS: '[ADMIN] Delete Ad Success',
  DELETE_AD_ERROR: '[ADMIN] Delete Ad Error',

  RESET_ADMIN_STATE: '[ADMIN] Reset Admin State',

  ADD_UPDATE_PLANS: '[ADMIN] Add / Update PLANS',
  ADD_UPDATE_PLANS_SUCCESS: '[ADMIN] Add / Update PLANS Success',
  ADD_UPDATE_PLANS_ERROR: '[ADMIN] Add / Update PLANS Error',

  GET_PLANS: '[ADMIN] Get Plans',
  GET_PLANS_SUCCESS: '[ADMIN] Get Plans Success',
  GET_PLANS_ERROR: '[ADMIN] Get Plans Error',

  GET_PLAN_BY_ID: '[ADMIN] Get Plans',
  GET_PLAN_BY_ID_SUCCESS: '[ADMIN] Get Plans Success',
  GET_PLAN_BY_ID_ERROR: '[ADMIN] Get Plans Error',

  DELETE_PLAN: '[ADMIN] Delete Plan',
  DELETE_PLAN_SUCCESS: '[ADMIN] Delete Plan Success',
  DELETE_PLAN_ERROR: '[ADMIN] Delete Plan Error',

  GET_TOKEN_COST_DETAIL: '[ADMIN] Get Token cost detail',
  GET_TOKEN_COST_DETAIL_SUCCESS: '[ADMIN] Get Token cost detail Success',
  GET_TOKEN_COST_DETAIL_ERROR: '[ADMIN] Get Token cost detail Error',

  CREATE_TOKEN_COST: '[ADMIN] Create Token Cost',
  CREATE_TOKEN_COST_SUCCESS: '[ADMIN] Create Token Cost Success',
  CREATE_TOKEN_COST_ERROR: '[ADMIN] Create Token Cost Error',

  UPDATE_TOKEN_COST: '[ADMIN] Update Token Cost',
  UPDATE_TOKEN_COST_SUCCESS: '[ADMIN] Update Token Cost Success',
  UPDATE_TOKEN_COST_ERROR: '[ADMIN] Update Token Cost Error',

  DELETE_TOKEN_COST: '[ADMIN] Delete Token Cost',
  DELETE_TOKEN_COST_SUCCESS: '[ADMIN] Delete Token Cost Success',
  DELETE_TOKEN_COST_ERROR: '[ADMIN] Delete Token Cost Error',

  GET_PAYOUT_LIST: '[ADMIN] Get Payout List',
  GET_PAYOUT_LIST_SUCCESS: '[ADMIN] Get Payout List Success',
  GET_PAYOUT_LIST_ERROR: '[ADMIN] Get Payout List Error',

  UPDATE_PAYOUT_STATUS: '[ADMIN] Update Payout Status',
  UPDATE_PAYOUT_STATUS_SUCCESS: '[ADMIN] Update Payout Status Success',
  UPDATE_PAYOUT_STATUS_ERROR: '[ADMIN] Update Payout Status Error',

  GET_PLATFORM_COMMISSION_LIST: '[ADMIN] Get Platform Commission List',
  GET_PLATFORM_COMMISSION_LIST_SUCCESS: '[ADMIN] Get Platform Commission List Success',
  GET_PLATFORM_COMMISSION_LIST_ERROR: '[ADMIN] Get Platform Commission List Error',

  UPDATE_PLATFORM_COMMISSION_STATUS: '[ADMIN] Update Platform Commission',
  UPDATE_PLATFORM_COMMISSION_STATUS_SUCCESS: '[ADMIN] Update Platform Commission Success',
  UPDATE_PLATFORM_COMMISSION_STATUS_ERROR: '[ADMIN] Update Platform Commission Error',

  GET_ALL_LISTENERS: '[ADMIN] Get all Listener',
  GET_ALL_LISTENERS_SUCCESS: '[ADMIN] Get all Listener Success',
  GET_ALL_LISTENERS_ERROR: '[ADMIN] Get all Listener Error',

  UPDATE_LISTENER: '[ADMIN] Update Listener',
  UPDATE_LISTENER_SUCCESS: '[ADMIN] Update Listener Success',
  UPDATE_LISTENER_ERROR: '[ADMIN] Update Listener Error',

  SEND_TOKENS_TO_ARTISTS: '[ADMIN] Send Tokens to Artists',
  SEND_TOKENS_TO_ARTISTS_SUCCESS: '[ADMIN] Send Tokens to Artists Success',
  SEND_TOKENS_TO_ARTISTS_ERROR: '[ADMIN] Send Tokens to Artists Error',

  GET_STREAM_TOKEN_DETAILS: '[ADMIN] Get stream token details',
  GET_STREAM_TOKEN_DETAILS_SUCCESS: '[ADMIN] Get  stream token details Success',
  GET_STREAM_TOKEN_DETAILS_ERROR: '[ADMIN] Get  stream token details Error',

  GET_ARTIST_TOKENS_LIST: '[ADMIN] Get artist tokens list',
  GET_ARTIST_TOKENS_LIST_SUCCESS: '[ADMIN] Get artist tokens list Success',
  GET_ARTIST_TOKENS_LIST_ERROR: '[ADMIN] Get artist tokens list Error',

  ADD_ARTIST_TOKENS: '[ADMIN] Add Artist Token',
  ADD_ARTIST_TOKENS_SUCCESS: '[ADMIN] Add Artist Token Success',
  ADD_ARTIST_TOKENS_ERROR: '[ADMIN] Add Artist Token Error',

  GET_DISTRIBUTE_TOKEN_LIST: '[ADMIN] Get Distribute Token List',
  GET_DISTRIBUTE_TOKEN_LIST_SUCCESS: '[ADMIN] Get Distribute Token List Success',
  GET_DISTRIBUTE_TOKEN_LIST_ERROR: '[ADMIN] Get Distribute Token List Error',

  UPDATE_DISTRIBUTE_TOKEN: '[ADMIN] Update Distribute Token',
  UPDATE_DISTRIBUTE_TOKEN_SUCCESS: '[ADMIN] Update Distribute Token Success',
  UPDATE_DISTRIBUTE_TOKEN_ERROR: '[ADMIN] Update Distribute Token Error',

  GET_ARTIST_STREAM_LIST: '[ADMIN] Get Artist Stream List',
  GET_ARTIST_STREAM_LIST_SUCCESS: '[ADMIN] Get Artist Stream List Success',
  GET_ARTIST_STREAM_LIST_ERROR: '[ADMIN] Get Artist Stream List Error',

  GET_ARTIST_STREAMS_DETAILS: '[ADMIN] Get Artist Stream Details',
  GET_ARTIST_STREAMS_DETAILS_SUCCESS: '[ADMIN] Get Artist Stream Details Success',
  GET_ARTIST_STREAMS_DETAILS_ERROR: '[ADMIN] Get Artist Stream Details Error',
};

export const GetAdminDashboard = createAction(AdminActions.GET_ADMIN_DASHBOARD, (params: any = {}) => params);
export const GetAdminDashboardSuccess = createAction(AdminActions.GET_ADMIN_DASHBOARD_SUCCESS, props<{ dashboard: any }>());
export const GetAdminDashboardError = createAction(AdminActions.GET_ADMIN_DASHBOARD_ERROR, props<{ error: string }>());

export const DeletePlan = createAction(AdminActions.DELETE_PLAN, props<{ id: string }>());
export const DeletePlanSuccess = createAction(AdminActions.DELETE_PLAN_SUCCESS, props<{ success: any }>());
export const DeletePlanError = createAction(AdminActions.DELETE_PLAN_ERROR, props<{ error: string }>());


export const GetPlanById = createAction(AdminActions.GET_PLAN_BY_ID, props<{ plan_id: string, query?: any }>());
export const GetPlanByIdSuccess = createAction(AdminActions.GET_PLAN_BY_ID_SUCCESS, props<{ plan: any }>());
export const GetPlanByIdError = createAction(AdminActions.GET_PLANS_ERROR, props<{ error: string }>());


export const GetAllPlans = createAction(AdminActions.GET_PLANS, (params: any = {}) => params);
export const GetAllPlansSuccess = createAction(AdminActions.GET_PLANS_SUCCESS, props<{ planlist: PlanList }>());
export const GetAllPlansError = createAction(AdminActions.GET_PLANS_ERROR, props<{ error: string }>());


export const AddUpdatePlans = createAction(AdminActions.ADD_UPDATE_PLANS, props<{ body: any }>());
export const AddUpdatePlansSuccess = createAction(AdminActions.ADD_UPDATE_PLANS_SUCCESS, props<{ plan: Plan  }>());
export const AddUpdatePlansError = createAction(AdminActions.ADD_UPDATE_PLANS_ERROR, props<{ error: string }>());


export const GetArtistProfile = createAction(AdminActions.GET_ARTIST_PROFILE, props<{ id: string, query?: any }>());
export const GetArtistProfileSuccess = createAction(AdminActions.GET_ARTIST_PROFILE_SUCCESS, props<{ artist: User }>());
export const GetArtistProfileError = createAction(AdminActions.GET_ARTIST_PROFILE_ERROR, props<{ error: string }>());

export const GetAllArtist = createAction(AdminActions.GET_ALL_ARTISTS, (params: any = {}) => params);
export const GetAllArtistSuccess = createAction(AdminActions.GET_ALL_ARTISTS_SUCCESS, props<{ artists: PaginatedUser }>());
export const GetAllArtistError = createAction(AdminActions.GET_ALL_ARTISTS_ERROR, props<{ error: string }>());

export const UpdateArtist = createAction(AdminActions.UPDATE_ARTIST, props<{ body: any }>());
export const UpdateArtistSuccess = createAction(AdminActions.UPDATE_ARTIST_SUCCESS, props<{ success: any }>());
export const UpdateArtistError = createAction(AdminActions.UPDATE_ARTIST_ERROR, props<{ error: string }>());

export const AcceptRejectArtistProfile = createAction(AdminActions.ACCEPT_REJECT_ARTIST_PROFILE, props<{ body: any }>());
export const AcceptRejectArtistProfileSuccess = createAction(AdminActions.ACCEPT_REJECT_ARTIST_PROFILE_SUCCESS, props<{ success: any }>());
export const AcceptRejectArtistProfileError = createAction(AdminActions.ACCEPT_REJECT_ARTIST_PROFILE_ERROR, props<{ error: string }>());

export const GetAllRadioStations = createAction(AdminActions.GET_ALL_RADIO_STATION, (params: any = {}) => params);
export const GetAllRadioStationsSuccess = createAction(AdminActions.GET_ALL_RADIO_STATION_SUCCESS, props<{ radio_stations: PaginatedUser }>());
export const GetAllRadioStationsError = createAction(AdminActions.GET_ALL_RADIO_STATION_ERROR, props<{ error: string }>());

export const GetRadioStationById = createAction(AdminActions.GET_RADIO_STATION_BY_ID, props<{ radio_station_id: string, query?: any }>());
export const GetRadioStationByIdSuccess = createAction(AdminActions.GET_RADIO_STATION_BY_ID_SUCCESS, props<{ radio_station: any }>());
export const GetRadioStationByIdError = createAction(AdminActions.GET_RADIO_STATION_BY_ID_ERROR, props<{ error: string }>());

export const AddUpdateRadioStation = createAction(AdminActions.ADD_UPDATE_RADIO_STATION, props<{ body: any }>());
export const AddUpdateRadioStationSuccess = createAction(AdminActions.ADD_UPDATE_RADIO_STATION_SUCCESS, props<{ radio_station: any }>());
export const AddUpdateRadioStationError = createAction(AdminActions.ADD_UPDATE_RADIO_STATION_ERROR, props<{ error: string }>());

export const DeleteRadioStation = createAction(AdminActions.DELETE_RADIO_STATION, props<{ id: string }>());
export const DeleteRadioStationSuccess = createAction(AdminActions.DELETE_RADIO_STATION_SUCCESS, props<{ success: any }>());
export const DeleteRadioStationError = createAction(AdminActions.DELETE_RADIO_STATION_ERROR, props<{ error: string }>());

export const GetAllGenres = createAction(AdminActions.GET_ALL_GENRES, (params: any = {}) => params);
export const GetAllGenresSuccess = createAction(AdminActions.GET_ALL_GENRES_SUCCESS, props<{ genres: Category[] }>());
export const GetAllGenresError = createAction(AdminActions.GET_ALL_GENRES_ERROR, props<{ error: string }>());

export const AddUpdateGenre = createAction(AdminActions.ADD_UPDATE_GENRE, props<{ body: any }>());
export const AddUpdateGenreSuccess = createAction(AdminActions.ADD_UPDATE_GENRE_SUCCESS, props<{ genres: Category[]  }>());
export const AddUpdateGenreError = createAction(AdminActions.ADD_UPDATE_GENRE_ERROR, props<{ error: string }>());

export const DeleteGenre = createAction(AdminActions.DELETE_GENRE, props<{ id: string }>());
export const DeleteGenreSuccess = createAction(AdminActions.DELETE_GENRE_SUCCESS, props<{ success: any }>());
export const DeleteGenreError = createAction(AdminActions.DELETE_GENRE_ERROR, props<{ error: string }>());

export const GetAllCategory = createAction(AdminActions.GET_ALL_CATEGORY, (params: any = {}) => params);
export const GetAllCategorySuccess = createAction(AdminActions.GET_ALL_CATEGORY_SUCCESS, props<{ categories: Category[] }>());
export const GetAllCategoryError = createAction(AdminActions.GET_ALL_CATEGORY_ERROR, props<{ error: string }>());

export const AddUpdateCategory = createAction(AdminActions.ADD_UPDATE_CATEGORY, props<{ body: any, params: any }>());
export const AddUpdateCategorySuccess = createAction(AdminActions.ADD_UPDATE_CATEGORY_SUCCESS, props<{ categories: Category[]  }>());
export const AddUpdateCategoryError = createAction(AdminActions.ADD_UPDATE_CATEGORY_ERROR, props<{ error: string }>());

export const DeleteCategory = createAction(AdminActions.DELETE_CATEGORY, props<{ id: string , params: any }>());
export const DeleteCategorySuccess = createAction(AdminActions.DELETE_CATEGORY_SUCCESS, props<{ success: any }>());
export const DeleteCategoryError = createAction(AdminActions.DELETE_CATEGORY_ERROR, props<{ error: string }>());

export const GetMusicList = createAction(AdminActions.GET_MUSIC_LIST, (params: any = {}) => params);
export const GetMusicListSuccess = createAction(AdminActions.GET_MUSIC_LIST_SUCCESS, props<{ musics: PaginatedMusic }>());
export const GetMusicListError = createAction(AdminActions.GET_MUSIC_LIST_ERROR, props<{ error: string }>());

export const GetMusicTrackList = createAction(AdminActions.GET_MUSIC_TRACK_LIST, props<{ music_id: string }>());
export const GetMusicTrackListSuccess = createAction(AdminActions.GET_MUSIC_TRACK_LIST_SUCCESS, props<{ tracks: Tracks[] }>());
export const GetMusicTrackListError = createAction(AdminActions.GET_MUSIC_TRACK_LIST_ERROR, props<{ error: string }>());

export const GetPodcastList = createAction(AdminActions.GET_PODCAST_LIST, (params: any = {}) => params);
export const GetPodcastListSuccess = createAction(AdminActions.GET_PODCAST_LIST_SUCCESS, props<{ podcasts: PaginatedPodcast }>());
export const GetPodcastListError = createAction(AdminActions.GET_PODCAST_LIST_ERROR, props<{ error: string }>());

export const GetPodcastEpisodeList = createAction(AdminActions.GET_PODCAST_EPISODE_LIST, props<{ podcast_id: string }>());
export const GetPodcastEpisodeListSuccess = createAction(AdminActions.GET_PODCAST_EPISODE_LIST_SUCCESS, props<{ episodes: Episode[] }>());
export const GetPodcastEpisodeListError = createAction(AdminActions.GET_PODCAST_EPISODE_LIST_ERROR, props<{ error: string }>());

export const GetSkitsList = createAction(AdminActions.GET_SKITS_LIST, (params: any = {}) => params);
export const GetSkitsListSuccess = createAction(AdminActions.GET_SKITS_LIST_SUCCESS, props<{ skits: PaginatedSkits }>());
export const GetSkitsListError = createAction(AdminActions.GET_SKITS_LIST_ERROR, props<{ error: string }>());


// ADS //

export const GetAds = createAction(AdminActions.GET_ADS, (params: any = {}) => params);
export const GetAdsSuccess = createAction(AdminActions.GET_ADS_SUCCESS, props<{ ads: PaginatedAds }>());
export const GetAdsError = createAction(AdminActions.GET_ADS_ERROR, props<{ error: string }>());

export const GetAdsAudience = createAction(AdminActions.GET_ADS_AUDIENCE, (params: any = {}) => params);
export const GetAdsAudienceSuccess = createAction(AdminActions.GET_ADS_AUDIENCE_SUCCESS, props<{ adsAudience: AdsAudience[] }>());
export const GetAdsAudienceError = createAction(AdminActions.GET_ADS_AUDIENCE_ERROR, props<{ error: string }>());

export const GetAd = createAction(AdminActions.GET_AD, props<{ id: string }>());
export const GetAdSuccess = createAction(AdminActions.GET_AD_SUCCESS, props<{ ad: Ads }>());
export const GetAdError = createAction(AdminActions.GET_AD_ERROR, props<{ error: string }>());

export const GetAdAudience = createAction(AdminActions.GET_AD_AUDIENCE, props<{ id: string }>());
export const GetAdAudienceSuccess = createAction(AdminActions.GET_AD_AUDIENCE_SUCCESS, props<{ adAudience: AdsAudience }>());
export const GetAdAudienceError = createAction(AdminActions.GET_AD_AUDIENCE_ERROR, props<{ error: string }>());

export const CreateAdAudience = createAction(AdminActions.CREATE_AD_AUDIENCE, props<{ body: any }>());
export const CreateAdAudienceSuccess = createAction(AdminActions.CREATE_AD_AUDIENCE_SUCCESS, props<{ adAudience: AdsAudience }>());
export const CreateAdAudienceError = createAction(AdminActions.CREATE_AD_AUDIENCE_ERROR, props<{ error: string }>());

export const CreateAd = createAction(AdminActions.CREATE_AD, props<{ body: any }>());
export const CreateAdSuccess = createAction(AdminActions.CREATE_AD_SUCCESS);
export const CreateAdError = createAction(AdminActions.CREATE_AD_ERROR, props<{ error: string }>());

export const UpdateAd = createAction(AdminActions.UPDATE_AD, props<{ id: string, body: any }>());
export const UpdateAdSuccess = createAction(AdminActions.UPDATE_AD_SUCCESS);
export const UpdateAdError = createAction(AdminActions.UPDATE_AD_ERROR, props<{ error: string }>());

export const UpdateAdAudience = createAction(AdminActions.UPDATE_AD_AUDIENCE, props<{ id: string, body: any }>());
export const UpdateAdAudienceSuccess = createAction(AdminActions.UPDATE_AD_AUDIENCE_SUCCESS, props<{ adAudience: AdsAudience }>());
export const UpdateAdAudienceError = createAction(AdminActions.UPDATE_AD_AUDIENCE_ERROR, props<{ error: string }>());

export const DeleteAdAudience = createAction(AdminActions.DELETE_AD_AUDIENCE, props<{ id: string }>());
export const DeleteAdAudienceSuccess = createAction(AdminActions.DELETE_AD_AUDIENCE_SUCCESS);
export const DeleteAdAudienceError = createAction(AdminActions.DELETE_AD_AUDIENCE_ERROR, props<{ error: string }>());

export const DeleteAd = createAction(AdminActions.DELETE_AD, props<{ id: string }>());
export const DeleteAdSuccess = createAction(AdminActions.DELETE_AD_SUCCESS);
export const DeleteAdError = createAction(AdminActions.DELETE_AD_ERROR, props<{ error: string }>());

export const GetTokenCostDetail = createAction(AdminActions.GET_TOKEN_COST_DETAIL, (params: any = {}) => params);
export const GetTokenCostDetailSuccess = createAction(AdminActions.GET_TOKEN_COST_DETAIL_SUCCESS, props<{ tokenCostDetail: any }>());
export const GetTokenCostDetailError = createAction(AdminActions.GET_TOKEN_COST_DETAIL_ERROR, props<{ error: string }>());

export const CreateTokenCost = createAction(AdminActions.CREATE_TOKEN_COST, props<{ body: any }>());
export const CreateTokenCostSuccess = createAction(AdminActions.CREATE_TOKEN_COST_SUCCESS);
export const CreateTokenCostError = createAction(AdminActions.CREATE_TOKEN_COST_ERROR, props<{ error: string }>());

export const UpdateTokenCost = createAction(AdminActions.UPDATE_TOKEN_COST, props<{ id: string, body: any }>());
export const UpdateTokenCostSuccess = createAction(AdminActions.UPDATE_TOKEN_COST_SUCCESS);
export const UpdateTokenCostError = createAction(AdminActions.UPDATE_TOKEN_COST_ERROR, props<{ error: string }>());

export const DeleteTokenCost = createAction(AdminActions.DELETE_TOKEN_COST, props<{ id: string }>());
export const DeleteTokenCostSuccess = createAction(AdminActions.DELETE_TOKEN_COST_SUCCESS);
export const DeleteTokenCostError = createAction(AdminActions.DELETE_TOKEN_COST_ERROR, props<{ error: string }>());

// Finance tab
export const GetPayoutList = createAction(AdminActions.GET_PAYOUT_LIST, (params: any = {}) => params);
export const GetPayoutListSuccess = createAction(AdminActions.GET_PAYOUT_LIST_SUCCESS, props<{ payout: any }>());
export const GetPayoutListError = createAction(AdminActions.GET_PAYOUT_LIST_ERROR, props<{ error: string }>());

export const UpdatePayoutStatus = createAction(AdminActions.UPDATE_PAYOUT_STATUS, (params: any = {}) => params);
export const UpdatePayoutStatusSuccess = createAction(AdminActions.UPDATE_PAYOUT_STATUS_SUCCESS, props<{ payout: any }>());
export const UpdatePayoutStatusError = createAction(AdminActions.UPDATE_PAYOUT_STATUS_ERROR, props<{ error: string }>());

// Platform Commission tab
export const GetPlatformCommissionList = createAction(AdminActions.GET_PLATFORM_COMMISSION_LIST, (params: any = {}) => params);
export const GetPlatformCommissionListSuccess = createAction(AdminActions.GET_PLATFORM_COMMISSION_LIST_SUCCESS, props<{ platform_commissions: any }>());
export const GetPlatformCommissionListError = createAction(AdminActions.GET_PLATFORM_COMMISSION_LIST_ERROR, props<{ error: string }>());

export const UpdatePlatformCommission = createAction(AdminActions.UPDATE_PLATFORM_COMMISSION_STATUS, props<{ body: any }>());
export const UpdatePlatformCommissionSuccess = createAction(AdminActions.UPDATE_PLATFORM_COMMISSION_STATUS_SUCCESS, props<{ platform_commissions: any }>());
export const UpdatePlatformCommissionError = createAction(AdminActions.UPDATE_PLATFORM_COMMISSION_STATUS_ERROR, props<{ error: string }>());

export const GetAllListener = createAction(AdminActions.GET_ALL_LISTENERS, (params: any = {}) => params);
export const GetAllListenerSuccess = createAction(AdminActions.GET_ALL_LISTENERS_SUCCESS, props<{ listeners: PaginatedUser }>());
export const GetAllListenerError = createAction(AdminActions.GET_ALL_LISTENERS_ERROR, props<{ error: string }>());

export const UpdateListener = createAction(AdminActions.UPDATE_LISTENER, props<{ body: any }>());
export const UpdateListenerSuccess = createAction(AdminActions.UPDATE_LISTENER_SUCCESS, props<{ listener: any }>());
export const UpdateListenerError = createAction(AdminActions.UPDATE_LISTENER_ERROR, props<{ error: string }>());

export const SendTokensToArtists = createAction(AdminActions.SEND_TOKENS_TO_ARTISTS, props<{ body: any }>());
export const SendTokensToArtistsSuccess = createAction(AdminActions.SEND_TOKENS_TO_ARTISTS_SUCCESS, props<{ tokensData: any }>());
export const SendTokensToArtistsError = createAction(AdminActions.SEND_TOKENS_TO_ARTISTS_ERROR, props<{ error: string }>());

export const StreamTokenDetails = createAction(AdminActions.GET_STREAM_TOKEN_DETAILS, (params: any = {}) => params);
export const StreamTokenDetailsSuccess = createAction(AdminActions.GET_STREAM_TOKEN_DETAILS_SUCCESS, props<{ streamTokenDetails: any }>());
export const StreamTokenDetailsError = createAction(AdminActions.GET_STREAM_TOKEN_DETAILS_ERROR, props<{ error: string }>());

export const GetArtistTokensList = createAction(AdminActions.GET_ARTIST_TOKENS_LIST, (params: any = {}) => params);
export const GetArtistTokensListSuccess = createAction(AdminActions.GET_ARTIST_TOKENS_LIST_SUCCESS, props<{ artistTokens: any }>());
export const GetArtistTokensListError = createAction(AdminActions.GET_ARTIST_TOKENS_LIST_ERROR, props<{ error: string }>());

export const AddArtistTokens = createAction(AdminActions.ADD_ARTIST_TOKENS, props<{ body: any }>());
export const AddArtistTokensSuccess = createAction(AdminActions.ADD_ARTIST_TOKENS_SUCCESS, props<{ artistToken: any }>());
export const AddArtistTokensError = createAction(AdminActions.ADD_ARTIST_TOKENS_ERROR, props<{ error: string }>());

// distribute-token-list
export const DistributeTokenList = createAction(AdminActions.GET_DISTRIBUTE_TOKEN_LIST, (params: any = {}) => params);
export const DistributeTokenListSuccess = createAction(AdminActions.GET_DISTRIBUTE_TOKEN_LIST_SUCCESS, props<{ distributeTokens: any }>());
export const DistributeTokenListError = createAction(AdminActions.GET_DISTRIBUTE_TOKEN_LIST_ERROR, props<{ error: string }>());

export const UpdateDistributeTokenStatus = createAction(AdminActions.UPDATE_DISTRIBUTE_TOKEN, props<{ params: any }>());
export const UpdateDistributeTokenStatusSuccess = createAction(AdminActions.UPDATE_DISTRIBUTE_TOKEN_SUCCESS, props<{ distributeToken: any }>());
export const UpdateDistributeTokenStatusError = createAction(AdminActions.UPDATE_DISTRIBUTE_TOKEN_ERROR, props<{ error: string }>());

// artist-stream-list
export const ArtistStreamList = createAction(AdminActions.GET_ARTIST_STREAM_LIST, (params: any = {}) => params);
export const ArtistStreamListSuccess = createAction(AdminActions.GET_ARTIST_STREAM_LIST_SUCCESS, props<{ artistStreams: any }>());
export const ArtistStreamListError = createAction(AdminActions.GET_ARTIST_STREAM_LIST_ERROR, props<{ error: string }>());

export const ArtistStreamsDetails = createAction(AdminActions.GET_ARTIST_STREAMS_DETAILS, (params: any = {}) => params);
export const ArtistStreamsDetailsSuccess = createAction(AdminActions.GET_ARTIST_STREAMS_DETAILS_SUCCESS, props<{ artistStreamsDetails: any }>());
export const ArtistStreamsDetailsError = createAction(AdminActions.GET_ARTIST_STREAMS_DETAILS_ERROR, props<{ error: string }>());

// ----------
export const ResetAdminState = createAction(AdminActions.RESET_ADMIN_STATE, (params: any = {}) => params);
