import {createReducer, on, Action} from '@ngrx/store';
import {
  ResetAdminState,
  GetArtistProfile,
  GetArtistProfileError,
  GetArtistProfileSuccess,
  GetAllArtist,
  GetAllArtistError,
  GetAllArtistSuccess,
  AcceptRejectArtistProfile,
  AcceptRejectArtistProfileSuccess,
  AcceptRejectArtistProfileError,
  UpdateArtist,
  UpdateArtistSuccess,
  UpdateArtistError,
  GetAllRadioStations,
  GetAllRadioStationsSuccess,
  GetAllRadioStationsError,
  DeleteRadioStation,
  DeleteRadioStationSuccess,
  DeleteRadioStationError,
  GetRadioStationById,
  GetRadioStationByIdSuccess,
  GetRadioStationByIdError,
  AddUpdateRadioStation,
  AddUpdateRadioStationSuccess,
  AddUpdateRadioStationError,
  GetAllGenres,
  GetAllGenresSuccess,
  GetAllGenresError,
  DeleteGenre,
  DeleteGenreError,
  DeleteGenreSuccess,
  DeleteCategory,
  DeleteCategorySuccess,
  DeleteCategoryError,
  GetAllCategory,
  GetAllCategoryError,
  GetAllCategorySuccess,
  AddUpdateGenreSuccess,
  AddUpdateCategorySuccess,
  GetMusicList,
  GetMusicListError,
  GetMusicListSuccess,
  GetMusicTrackList,
  GetMusicTrackListError,
  GetMusicTrackListSuccess,
  GetPodcastEpisodeList,
  GetPodcastEpisodeListError,
  GetPodcastEpisodeListSuccess,
  GetPodcastList,
  GetPodcastListError,
  GetPodcastListSuccess,
  GetSkitsList,
  GetSkitsListError,
  GetSkitsListSuccess,

  GetAds,
  GetAdsError,
  GetAdsSuccess,
  GetAdsAudience,
  GetAdsAudienceSuccess,
  GetAdsAudienceError,
  GetAd,
  GetAdSuccess,
  GetAdError,
  GetAdAudience,
  GetAdAudienceSuccess,
  GetAdAudienceError,
  CreateAdAudience,
  CreateAdAudienceSuccess,
  CreateAdAudienceError,
  CreateAd,
  CreateAdSuccess,
  CreateAdError,
  UpdateAd,
  UpdateAdSuccess,
  UpdateAdError,
  UpdateAdAudience,
  UpdateAdAudienceSuccess,
  UpdateAdAudienceError,
  DeleteAdAudience,
  DeleteAdAudienceSuccess,
  DeleteAdAudienceError,
  DeleteAd,
  DeleteAdSuccess,
  DeleteAdError,
  AddUpdatePlans,
  AddUpdatePlansSuccess,
  AddUpdatePlansError,
  GetAllPlansSuccess,
  GetAllPlansError,
  GetAllPlans,
  GetPlanByIdError,
  GetPlanById,
  GetPlanByIdSuccess,
  DeletePlan,
  DeletePlanSuccess,
  DeletePlanError,
  GetTokenCostDetail,
  GetTokenCostDetailSuccess,
  GetTokenCostDetailError,
  CreateTokenCost,
  CreateTokenCostSuccess,
  CreateTokenCostError,
  UpdateTokenCost,
  UpdateTokenCostSuccess,
  UpdateTokenCostError,
  DeleteTokenCost,
  DeleteTokenCostSuccess,
  DeleteTokenCostError,
  GetAdminDashboard,
  GetAdminDashboardSuccess,
  GetAdminDashboardError,
  GetPayoutList,
  GetPayoutListSuccess,
  UpdatePayoutStatusSuccess,
  UpdatePayoutStatus,
  UpdatePayoutStatusError,
  GetPayoutListError,
  GetPlatformCommissionList,
  GetPlatformCommissionListSuccess,
  GetPlatformCommissionListError,
  UpdatePlatformCommission,
  UpdatePlatformCommissionSuccess,
  UpdatePlatformCommissionError,
  GetAllListener,
  GetAllListenerSuccess,
  GetAllListenerError,
  UpdateListener,
  UpdateListenerSuccess,
  UpdateListenerError,
  SendTokensToArtists,
  SendTokensToArtistsSuccess,
  SendTokensToArtistsError,
  StreamTokenDetails, StreamTokenDetailsSuccess, StreamTokenDetailsError,
  GetArtistTokensList,
  GetArtistTokensListSuccess,
  GetArtistTokensListError,
  AddArtistTokens,
  AddArtistTokensSuccess,
  AddArtistTokensError,
  DistributeTokenListError,
  DistributeTokenListSuccess,
  DistributeTokenList,
  ArtistStreamList,
  ArtistStreamListSuccess,
  ArtistStreamListError,
  UpdateDistributeTokenStatus,
  UpdateDistributeTokenStatusSuccess,
  UpdateDistributeTokenStatusError,
  ArtistStreamsDetails,
  ArtistStreamsDetailsSuccess,
  ArtistStreamsDetailsError
} from './admin.actions';
import {
  Category,
  Episode,
  PaginatedMusic,
  PaginatedPodcast,
  PaginatedSkits,
  PaginatedUser,
  Tracks,
  User,
  PaginatedAds,
  AdsAudience,
  Ads,
  Plan,
  PlanList
} from "@frontend/data-models";

export interface AdminState {
  success: string;
  error: string;
  artist: User;
  artists: PaginatedUser;
  musics: PaginatedMusic,
  podcasts: PaginatedPodcast,
  skits: PaginatedSkits,
  radio_stations: any;
  radio_station: any;
  genres: Category[],
  categories: Category[],
  tracks: Tracks[],
  episodes: Episode[],
  ads: PaginatedAds;
  adsAudience: AdsAudience[];
  ad: Ads;
  adAudience: AdsAudience;
  plan:Plan,
  planlist:PlanList,
  tokenCostDetail: any;
  dashboard: any;
  payout: any;
  platform_commissions: [],
  listeners: PaginatedUser;
  listener: any;
  tokensData: any;
  streamTokenDetails: any;
  artistTokens: any;
  artistToken: any;
  distributeTokens: any;
  distributeToken: any;
  artistStreams: any;
  artistStreamsDetails: any;
}

const initialAdminState: AdminState = {
  error: '', success: '', artist: null, artists: null, radio_stations: null, radio_station: null, genres: null, categories: null,
  musics: null, tracks: [],  podcasts: null,  episodes: [], skits: null, ads: null, adsAudience: [], ad: null, adAudience: null,
  plan:null, planlist:null, tokenCostDetail: null, dashboard: null, payout: null, platform_commissions: [], listeners: null, listener: null, tokensData: null, streamTokenDetails: null,
  artistTokens: null, artistToken: null, distributeTokens: null, distributeToken: null, artistStreams: null, artistStreamsDetails: null
};

export function reducer(state = initialAdminState, action: Action) {

  const adminReducer = createReducer(
    initialAdminState,
    on(ResetAdminState, (state, {params}) => {
      return {
        ...state,
        ...params
      };
    }),

    on(GetAdminDashboard, (state) => ({...state, error: '', success: '', dashboard: null})),
    on(GetAdminDashboardSuccess, (state, {dashboard}) => ({...state, error: '', success: '', dashboard})),
    on(GetAdminDashboardError, (state, {error}) => ({...state, error, success: '', dashboard: null})),

    on(DeletePlan, (state) => ({...state, error: '', success: ''})),
    on(DeletePlanSuccess, (state, {success}) => ({...state, error: '', success})),
    on(DeletePlanError, (state, {error}) => ({...state, error, success: ''})),

    on(GetAllPlans, (state) => ({...state, error: '', success: '', planlist: null})),
    on(GetAllPlansSuccess, (state, {planlist}) => ({...state, error: '', success: '', planlist})),
    on(GetAllPlansError, (state, {error}) => ({...state, error, success: '', planlist: null})),

    on(GetPlanById, (state) => ({...state, error: '', success: '', plan: null})),
    on(GetPlanByIdSuccess, (state, {plan}) => ({...state, error: '', success: '', plan})),
    on(GetPlanByIdError, (state, {error}) => ({...state, error, success: '', plan: null})),


    on(AddUpdatePlans, (state) => ({...state, error: '', success: ''})),
    on(AddUpdatePlansSuccess, (state,{plan}) => ({...state, error: '',plan, success: 'Updated successfully'})),
    on(AddUpdatePlansError, (state, {error}) => ({...state, error, success: ''})),


    on(GetArtistProfile, (state) => ({...state, error: '', success: '', artist: null})),
    on(GetArtistProfileSuccess, (state, {artist}) => ({...state, error: '', success: '', artist})),
    on(GetArtistProfileError, (state, {error}) => ({...state, error, success: '', artist: null})),

    on(GetAllArtist, (state) => ({...state, error: '', success: '', artists: null})),
    on(GetAllArtistSuccess, (state, {artists}) => ({...state, error: '', success: '', artists})),
    on(GetAllArtistError, (state, {error}) => ({...state, error, success: '', artists: null})),

    on(AcceptRejectArtistProfile, (state) => ({...state, error: '', success: ''})),
    on(AcceptRejectArtistProfileSuccess, (state, {success}) => ({...state, error: '', success})),
    on(AcceptRejectArtistProfileError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdateArtist, (state) => ({...state, error: '', success: ''})),
    on(UpdateArtistSuccess, (state, {success}) => ({...state, error: '', success})),
    on(UpdateArtistError, (state, {error}) => ({...state, error, success: ''})),

    on(GetAllRadioStations, (state) => ({...state, error: '', success: '', radio_stations: null})),
    on(GetAllRadioStationsSuccess, (state, {radio_stations}) => ({...state, error: '', success: '', radio_stations})),
    on(GetAllRadioStationsError, (state, {error}) => ({...state, error, success: '', radio_stations: null})),

    on(GetRadioStationById, (state) => ({...state, error: '', success: '', radio_station: null})),
    on(GetRadioStationByIdSuccess, (state, {radio_station}) => ({...state, error: '', success: '', radio_station})),
    on(GetRadioStationByIdError, (state, {error}) => ({...state, error, success: '', radio_station: null})),

    on(AddUpdateRadioStation, (state) => ({...state, error: '', success: ''})),
    on(AddUpdateRadioStationSuccess, (state) => ({...state, error: '', success: 'Updated successfully'})),
    on(AddUpdateRadioStationError, (state, {error}) => ({...state, error, success: ''})),

    on(DeleteRadioStation, (state) => ({...state, error: '', success: ''})),
    on(DeleteRadioStationSuccess, (state, {success}) => ({...state, error: '', success})),
    on(DeleteRadioStationError, (state, {error}) => ({...state, error, success: ''})),

    on(GetAllGenres, (state) => ({...state, error: '', success: '', radio_stations: null})),
    on(GetAllGenresSuccess, (state, {genres}) => ({...state, error: '', success: '', genres})),
    on(GetAllGenresError, (state, {error}) => ({...state, error, success: '', radio_stations: null})),

    on(DeleteGenre, (state) => ({...state, error: '', success: ''})),
    on(DeleteGenreSuccess, (state, {success}) => ({...state, error: '', success})),
    on(DeleteGenreError, (state, {error}) => ({...state, error, success: ''})),

    on(GetAllCategory, (state) => ({...state, error: '', success: '', categories: []})),
    on(GetAllCategorySuccess, (state, {categories}) => ({...state, error: '', success: '', categories})),
    on(GetAllCategoryError, (state, {error}) => ({...state, error, success: '', categories: []})),

    on(DeleteCategory, (state) => ({...state, error: '', success: ''})),
    on(DeleteCategorySuccess, (state, {success}) => ({...state, error: '', success})),
    on(DeleteCategoryError, (state, {error}) => ({...state, error, success: ''})),

    on(AddUpdateGenreSuccess, (state) => ({...state, error: '', success: 'Genre updated successfully'})),
    on(AddUpdateCategorySuccess, (state) => ({...state, error: '', success: 'Category updated successfully'})),

    on(GetMusicList, (state) => ({...state, error: '', musics: null, success: ''})),
    on(GetMusicListSuccess, (state, {musics}) => ({...state, error: '', success: '', musics})),
    on(GetMusicListError, (state, {error}) => ({...state, error, musics: null, success: ''})),

    on(GetMusicTrackList, (state) => ({...state, error: '', tracks: [], success: ''})),
    on(GetMusicTrackListSuccess, (state, {tracks}) => ({...state, error: '', success: '', tracks})),
    on(GetMusicTrackListError, (state, {error}) => ({...state, error, tracks: [], success: ''})),

    on(GetPodcastList, (state) => ({...state, error: '', podcasts: null, success: ''})),
    on(GetPodcastListSuccess, (state, {podcasts}) => ({...state, error: '', success: '', podcasts})),
    on(GetPodcastListError, (state, {error}) => ({...state, error, podcasts: null, success: ''})),

    on(GetPodcastEpisodeList, (state) => ({...state, error: '', episodes: [], success: ''})),
    on(GetPodcastEpisodeListSuccess, (state, {episodes}) => ({...state, error: '', success: '', episodes})),
    on(GetPodcastEpisodeListError, (state, {error}) => ({...state, error, episodes: [], success: ''})),

    on(GetSkitsList, (state) => ({...state, error: '', skits: null, success: ''})),
    on(GetSkitsListSuccess, (state, {skits}) => ({...state, error: '', success: '', skits})),
    on(GetSkitsListError, (state, {error}) => ({...state, error, skits: null, success: ''})),

    // ADS //

    on(GetAds, (state) => ({...state, ads: null, error: '', success: ''})),
    on(GetAdsSuccess, (state, {ads}) => ({...state, ads, error: '', success: ''})),
    on(GetAdsError, (state, {error}) => ({...state, ads: null, error, success: ''})),

    on(GetAdsAudience, (state) => ({...state, adsAudience: [], error: '', success: ''})),
    on(GetAdsAudienceSuccess, (state, {adsAudience}) => ({...state, adsAudience, error: '', success: ''})),
    on(GetAdsAudienceError, (state, {error}) => ({...state, adsAudience: [], error, success: ''})),

    on(GetAd, (state) => ({...state, ad: null, error: '', success: ''})),
    on(GetAdSuccess, (state, {ad}) => ({...state, ad, error: '', success: ''})),
    on(GetAdError, (state, {error}) => ({...state, ad: null, error, success: ''})),

    on(GetAdAudience, (state) => ({...state, adAudience: null, error: '', success: ''})),
    on(GetAdAudienceSuccess, (state, {adAudience}) => ({...state, adAudience, error: '', success: ''})),
    on(GetAdAudienceError, (state, {error}) => ({...state, adAudience: null, error, success: ''})),

    on(CreateAdAudience, (state) => ({...state, adAudience: null, error: '', success: ''})),
    on(CreateAdAudienceSuccess, (state, {adAudience}) => ({...state, adAudience, error: '', success: ''})),
    on(CreateAdAudienceError, (state, {error}) => ({...state, adAudience: null, error, success: ''})),

    on(CreateAd, (state) => ({...state, error: '', success: ''})),
    on(CreateAdSuccess, (state) => ({...state, error: '', success: ''})),
    on(CreateAdError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdateAd, (state) => ({...state, error: '', success: ''})),
    on(UpdateAdSuccess, (state) => ({...state, error: '', success: 'ad updated success'})),
    on(UpdateAdError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdateAdAudience, (state) => ({...state, adAudience: null, error: '', success: ''})),
    on(UpdateAdAudienceSuccess, (state, {adAudience}) => ({...state, adAudience, error: '', success: ''})),
    on(UpdateAdAudienceError, (state, {error}) => ({...state, adAudience: null, error, success: ''})),

    on(DeleteAdAudience, (state) => ({...state, error: '', success: ''})),
    on(DeleteAdAudienceSuccess, (state) => ({...state, error: '', success: 'Audience deleted successfully'})),
    on(DeleteAdAudienceError, (state, {error}) => ({...state, error, success: ''})),

    on(DeleteAd, (state) => ({...state, error: '', success: ''})),
    on(DeleteAdSuccess, (state) => ({...state, error: '', success: 'Ad deleted successfully'})),
    on(DeleteAdError, (state, {error}) => ({...state, error, success: ''})),

    on(GetTokenCostDetail, (state) => ({...state, tokenCostDetail: null, error: '', success: ''})),
    on(GetTokenCostDetailSuccess, (state, {tokenCostDetail}) => ({...state, tokenCostDetail, error: '', success: ''})),
    on(GetTokenCostDetailError, (state, {error}) => ({...state, ad: null, error, success: ''})),

    on(CreateTokenCost, (state) => ({...state, error: '', success: ''})),
    on(CreateTokenCostSuccess, (state) => ({...state, error: '', success: ''})),
    on(CreateTokenCostError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdateTokenCost, (state) => ({...state, error: '', success: ''})),
    on(UpdateTokenCostSuccess, (state) => ({...state, error: '', success: 'Updated success'})),
    on(UpdateTokenCostError, (state, {error}) => ({...state, error, success: ''})),

    on(DeleteTokenCost, (state) => ({...state, error: '', success: ''})),
    on(DeleteTokenCostSuccess, (state) => ({...state, error: '', success: 'Deleted successfully'})),
    on(DeleteTokenCostError, (state, {error}) => ({...state, error, success: ''})),

    on(GetPayoutList, (state) => ({...state, payout: null, error: '', success: ''})),
    on(GetPayoutListSuccess, (state, {payout}) => ({...state, payout, error: '', success: ''})),
    on(GetPayoutListError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdatePayoutStatus, (state) => ({...state, payout: null, error: '', success: ''})),
    on(UpdatePayoutStatusSuccess, (state, {payout}) => ({...state, payout, error: '', success: ''})),
    on(UpdatePayoutStatusError, (state, {error}) => ({...state, error, success: ''})),

    on(GetPlatformCommissionList, (state) => ({...state, payout: [], error: '', success: ''})),
    on(GetPlatformCommissionListSuccess, (state, {platform_commissions}) => ({...state, platform_commissions, error: '', success: ''})),
    on(GetPlatformCommissionListError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdatePlatformCommission, (state) => ({...state, platform_commissions: [], error: '', success: ''})),
    on(UpdatePlatformCommissionSuccess, (state, {platform_commissions}) => ({...state, platform_commissions, error: '', success: 'Updated successfully'})),
    on(UpdatePlatformCommissionError, (state, {error}) => ({...state, error, success: ''})),

    on(GetAllListener, (state) => ({...state, error: '', success: '', listeners: null})),
    on(GetAllListenerSuccess, (state, {listeners}) => ({...state, error: '', success: '', listeners})),
    on(GetAllListenerError, (state, {error}) => ({...state, error, success: '', listeners: null})),

    on(UpdateListener, (state) => ({...state, listener: null, error: '', success: ''})),
    on(UpdateListenerSuccess, (state, {listener}) => ({...state, listener, error: '', success: 'Listener Updated successfully'})),
    on(UpdateListenerError, (state, {error}) => ({...state, error, success: ''})),

    on(SendTokensToArtists, (state) => ({...state, tokensData: null, error: '', success: ''})),
    on(SendTokensToArtistsSuccess, (state, {tokensData}) => ({...state, tokensData, error: '', success: 'Tokens send successfully'})),
    on(SendTokensToArtistsError, (state, {error}) => ({...state, error, success: ''})),

    on(StreamTokenDetails, (state) => ({...state, streamTokenDetails: null, error: '', success: ''})),
    on(StreamTokenDetailsSuccess, (state, {streamTokenDetails}) => ({...state, streamTokenDetails, error: '', success: 'Tokens send successfully'})),
    on(StreamTokenDetailsError, (state, {error}) => ({...state, error, success: ''})),

    on(GetArtistTokensList, (state) => ({...state, artistTokens: null, error: '', success: ''})),
    on(GetArtistTokensListSuccess, (state, {artistTokens}) => ({...state, artistTokens, error: '', success: ''})),
    on(GetArtistTokensListError, (state, {error}) => ({...state, error, success: ''})),

    on(AddArtistTokens, (state) => ({...state, artistToken: null, error: '', success: ''})),
    on(AddArtistTokensSuccess, (state, {artistToken}) => ({...state, artistToken, error: '', success: 'Tokens added successfully'})),
    on(AddArtistTokensError, (state, {error}) => ({...state, error, success: ''})),

    on(DistributeTokenList, (state) => ({...state, distributeTokens: null, error: '', success: ''})),
    on(DistributeTokenListSuccess, (state, {distributeTokens}) => ({...state, distributeTokens, error: '', success: ''})),
    on(DistributeTokenListError, (state, {error}) => ({...state, error, success: ''})),

    on(UpdateDistributeTokenStatus, (state) => ({...state, distributeToken: null, error: '', success: ''})),
    on(UpdateDistributeTokenStatusSuccess, (state, {distributeToken}) => ({...state, distributeToken, error: '', success: 'Token Distributed successfully'})),
    on(UpdateDistributeTokenStatusError, (state, {error}) => ({...state, error, success: ''})),

    on(ArtistStreamList, (state) => ({...state, artistStreams: null, error: '', success: ''})),
    on(ArtistStreamListSuccess, (state, {artistStreams}) => ({...state, artistStreams, error: '', success: ''})),
    on(ArtistStreamListError, (state, {error}) => ({...state, error, success: ''})),

    on(ArtistStreamsDetails, (state) => ({...state, artistStreamsDetails: null, error: '', success: ''})),
    on(ArtistStreamsDetailsSuccess, (state, {artistStreamsDetails}) => ({...state, artistStreamsDetails, error: '', success: ''})),
    on(ArtistStreamsDetailsError, (state, {error}) => ({...state, error, success: ''})),
    // ---------
  );
  return adminReducer(state, action);
}
