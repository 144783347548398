import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@frontend/app-config';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  ApiUrl!: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}`;
  }

  getAdminDashboard(params: any) {
    return this.http.get(`${this.ApiUrl}admin/dashboard`, { params });
  }

  getArtistProfile(id: string, query: any) {
    return this.http.get(`${this.ApiUrl}artists/${id}`, { params: query });
  }

  getAllArtists(params: any) {
    return this.http.get(`${this.ApiUrl}artists`, { params });
  }

  acceptRejectArtistProfile(body: any) {
    return this.http.put(`${this.ApiUrl}admin/accept-reject-artist`, body);
  }

  updateArtist(body: any) {
    return this.http.put(`${this.ApiUrl}artists/${body.id}`, body);
  }

  getAllRadioStations(params: any) {
    return this.http.get(`${this.ApiUrl}radio-stations`, { params });
  }

  getRadioStationById(id: string, query: any) {
    return this.http.get(`${this.ApiUrl}radio-stations/${id}`, {
      params: query,
    });
  }

  getPlanById(id: string, query: any) {
    return this.http.get(`${this.ApiUrl}radio-stations/${id}`, {
      params: query,
    });
  }

  addUpdateRadioStation(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}radio-stations/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}radio-stations`, body);
    }
  }

  deleteRadioStation(id: string) {
    return this.http.delete(`${this.ApiUrl}radio-stations/${id}`);
  }

  getAllGenres(params: any = {}) {
    return this.http.get(`${this.ApiUrl}music/genres`, { params });
  }

  addUpdateGenre(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}music/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}music`, body);
    }
  }

  deleteGenre(id: string) {
    return this.http.delete(`${this.ApiUrl}music/${id}`);
  }

  getAllCategories(params: any = {}) {
    if (params?.type === 'skit') {
      return this.http.get(`${this.ApiUrl}skits/categories`, { params });
    } else {
      return this.http.get(`${this.ApiUrl}podcasts/categories`, { params });
    }
  }

  addUpdateCategory(body: any, params: any = {}) {
    if (body._id) {
      if (params?.type === 'skit') {
        return this.http.put(
          `${this.ApiUrl}skits/categories/${body._id}`,
          body
        );
      }
      return this.http.put(
        `${this.ApiUrl}podcasts/categories/${body._id}`,
        body
      );
    } else {
      if (params?.type === 'skit') {
        return this.http.post(`${this.ApiUrl}skits/categories`, body);
      }
      return this.http.post(`${this.ApiUrl}podcasts/categories`, body);
    }
  }

  deleteCategories(id: string, params: any = {}) {
    if (params?.type === 'skit') {
      return this.http.delete(`${this.ApiUrl}skits/categories/${id}`, {
        params,
      });
    } else {
      return this.http.delete(`${this.ApiUrl}podcasts/categories/${id}`, {
        params,
      });
    }
  }

  getMusics(params: any) {
    return this.http.get(`${this.ApiUrl}albums`, { params });
  }

  getMusicTracks(albumId: string) {
    return this.http.get(`${this.ApiUrl}albums/${albumId}/tracks`);
  }

  getPodcasts(params: any) {
    return this.http.get(`${this.ApiUrl}podcasts`, { params });
  }

  getPodcastEpisodes(podcastId: string) {
    return this.http.get(`${this.ApiUrl}podcasts/${podcastId}/episodes`);
  }

  getSkits(params: any) {
    return this.http.get(`${this.ApiUrl}skits`, { params });
  }

  // ADS //

  getAds(params: any) {
    return this.http.get(`${this.ApiUrl}admin/getAds`, { params });
  }

  getAdsAudience(params: any) {
    return this.http.get(`${this.ApiUrl}admin/getAdsAudience`, { params });
  }

  getAd(id: string) {
    return this.http.get(`${this.ApiUrl}admin/getAd/${id}`);
  }

  getPlans(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/subscription/plans`, {
      params,
    });
  }

  getAdAudience(id: string) {
    return this.http.get(`${this.ApiUrl}admin/getAdAudience/${id}`);
  }

  addAd(body: any) {
    return this.http.post(`${this.ApiUrl}admin/addAd`, body);
  }

  addAdAudience(body: any) {
    return this.http.post(`${this.ApiUrl}admin/addAdAudience`, body);
  }

  updateAd(id: string, body: any) {
    return this.http.put(`${this.ApiUrl}admin/updateAd/${id}`, body);
  }

  addPlan(body: any) {
    // eslint-disable-next-line no-debugger
    if (body?._id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-subscription/${body._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club/create-subscription`, body);
    }
  }

  updateAdAudience(id: string, body: any) {
    return this.http.put(`${this.ApiUrl}admin/updateAdAudience/${id}`, body);
  }

  deleteAd(id: string) {
    return this.http.delete(`${this.ApiUrl}admin/deleteAd/${id}`);
  }
  deletePlan(id: string) {
    return this.http.delete(`${this.ApiUrl}fan-club/remove-subscription/${id}`);
  }

  deleteAdAudience(id: string) {
    return this.http.delete(`${this.ApiUrl}admin/deleteAdAudience/${id}`);
  }

  addToken(body: any, isId: any) {
    if (isId) {
      return this.http.put(`${this.ApiUrl}/tokens/edit-tokens`, body);
    } else {
      return this.http.post(`${this.ApiUrl}/tokens/add-tokens`, body);
    }
  }

  getTokens(params: any) {
    return this.http.get(`${this.ApiUrl}/tokens/get-tokens`, { params });
  }

  deleteToken(id: string) {
    return this.http.delete(`${this.ApiUrl}tokens/remove-tokens/${id}`);
  }

  getAdminSubscriptionPlans(params: any) {
    return this.http.get(`${this.ApiUrl}/subscription/plan`, { params });
  }

  addUpdateAdminSubscriptionPlan(body: any) {
    if (body?._id) {
      return this.http.put(
        `${this.ApiUrl}subscription/plan/${body?._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}subscription/plan`, body);
    }
  }
  deleteSubscriptionPlan(id: string) {
    return this.http.delete(`${this.ApiUrl}subscription/plan/${id}`);
  }

  getFeatures(params: any) {
    return this.http.get(`${this.ApiUrl}subscription/get-feature`, { params });
  }

  // Token cost
  addTokenCost(body: any, isId: any) {
    if (isId) {
      return this.http.put(`${this.ApiUrl}admin/update-token-cost/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}admin/create-token-cost`, body);
    }
  }

  getTokenCost(params: any) {
    return this.http.get(`${this.ApiUrl}admin/get-token-cost`, { params });
  }

  deleteTokenCost(id: string) {
    return this.http.delete(`${this.ApiUrl}admin/delete-token-cost/${id}`);
  }

  // Finance tab
  getPayout(params: any) {
    return this.http.get(`${this.ApiUrl}admin/getPayout`, {params});
  }

  updatePayoutStatus(params: any) {
    return this.http.patch(`${this.ApiUrl}admin/updatePayoutStatus`, params);
  }

  // Platform Commission
  getPlatformCommissions (params: any) {
    return this.http.get(`${this.ApiUrl}admin/getPlatformCommissionSetting`, {params});
  }

  updatePlatformCommission(body: any) {
    return this.http.post(`${this.ApiUrl}admin/editPlatformCommissionSetting`, body);
  }

  getAllListeners(params: any) {
    return this.http.get(`${this.ApiUrl}admin/getAllListeners`, { params });
  }

  updateListener(body: any) {
    return this.http.put(`${this.ApiUrl}admin/updateListener`, body);
  }

  sendTokensToArtist(body: any) {
    return this.http.post(`${this.ApiUrl}admin/sendTokensToArtist`, body);
  }

  getStreamTokenDetails(params: any) {
    return this.http.get(`${this.ApiUrl}admin/getStreamTokenDetails`, {params});
  }

  getArtistTokensList(params: any) {
    return this.http.get(`${this.ApiUrl}admin/get-admin-tokens-data`, {params});
  }

  AddArtistTokens(body: any) {
    return this.http.post(`${this.ApiUrl}admin/add-tokens-to-artist-distribution`, body);
  }

  getDistributeToken(params: any) {
    return this.http.get(`${this.ApiUrl}tokens/distribute-token-list`, {params});
  }

  UpdateDistributeTokenStatus(params: any) {
    return this.http.put(`${this.ApiUrl}tokens/update-distribute-token`, {params});
  }

  getArtistStreams(params: any) {
    return this.http.get(`${this.ApiUrl}admin/artist-views-data`, {params});
  }

  getArtistStreamsDetails(params: any) {
    return this.http.get(`${this.ApiUrl}admin/artist-csv-data`, {params});
  }
}
