import {
  Component,
  OnInit,
  EventEmitter,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  AdminState,
  getArtistToken,
  sendTokens,
  SendTokensToArtists,
  StreamTokenDetails
} from "@frontend/admin-store";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'frontend-finance-send-token-artist-modal',
  templateUrl: './finance-send-token-artist-modal.component.html',
  styleUrls: ['./finance-send-token-artist-modal.component.scss'],
})

export class FinanceSendTokenArtistModalComponent implements OnInit {
  public closeEvent: EventEmitter<any> = new EventEmitter();
  unsubscriber = new Subject();
  isSendToken = false;
  tokenDetails: any;
  customDatatableSettings: any = {};
  dataCount = 500000;
  queryParams: any = {};
  constructor(
    public modalRef: BsModalRef<FinanceSendTokenArtistModalComponent>,
    private adminStore: Store<AdminState>,
    private toastr: ToastrService,
  ) {
    this.adminStore.pipe(select(sendTokens))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data?.success && this.isSendToken) {
          this.closeEvent.emit(true);
          if (data?.message === 'Successful') {
            this.toastr.success(data.message);
          } else {
            this.toastr.warning(data.message);
          }
          this.isSendToken = false
        }
      })

    this.adminStore.pipe(select(getArtistToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data?.success) {
          if (data.message === 'Successful') {
            this.tokenDetails = data.data;
            this.dataCount = data.data.artistStreams.count.total
            console.log('tokenDetails', this.tokenDetails)
          } else {
            this.tokenDetails = {
              totalStreamWiseAmount: 0,
              totalStreamsCount: 0
            }
          }

          console.log('----sfvj', this.tokenDetails)
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      withPage: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['name', 'streams', 'tokens'],
      order: [[1, 'desc']],
      searching: false,
    }
    console.log('----', this.tokenDetails)
    this.adminStore.dispatch(StreamTokenDetails({
      params: {
        limit: 5,
        page: 1
      }
    }));
  }

  getAllWalletTransaction = (page = 1) => {
    this.adminStore.dispatch(StreamTokenDetails({
      params: {
        page, limit: 5,
      }
    }));
  }

  changePage(event: any) {
    this.adminStore.dispatch(StreamTokenDetails({
      params: {
        page: event.page, limit: 5,
      }
    }));
  }
  sendTokens() {
    console.log('this.tokenDetails?.totalStreamsCount <= 0 && this.tokenDetails?.totalStreamWiseAmount <= 0', this.tokenDetails?.totalStreamsCount <= 0 || this.tokenDetails?.totalStreamWiseAmount <= 0)
    if (this.tokenDetails?.totalStreamsCount <= 0 || this.tokenDetails?.totalStreamWiseAmount <= 0) {
      this.toastr.error('You have already sent tokens to all artists');
      return;
    }
    const body = {}
    this.isSendToken = true
    this.adminStore.dispatch(SendTokensToArtists({body}));
    this.modalRef.hide();
  }

  // ngOnInit(): void {
  //
  // }


}
