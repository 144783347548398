import { Component, OnInit, ViewChild } from '@angular/core';
import {CustomTableComponent} from "@frontend/shared";
import * as dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {
  AdminState,
  ArtistStreamList,
  ArtistStreamsDetails,
  getArtistStreams,
  getArtistStreamsDetails
} from '@frontend/admin-store';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'artist-streams',
  templateUrl: './artist-streams.component.html',
  styleUrls: ['./artist-streams.component.scss'],
})

export class ArtistStreamsComponent implements OnInit {
  currentUser: any;
  submitted = false;
  unsubscriber = new Subject();
  filter: UntypedFormGroup;
  types: any[] = [
    {
      name: 'Video',
      id: 'video'
    },
    {
      name: 'Podcast',
      id: 'podcast'
    },
    {
      name: 'Music',
      id: 'music'
    },
  ];
  month: any[] = [
    {
      name: 'Current Week',
      id: `${dayjs().startOf('week').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Current Month',
      id: `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 30 days',
      id: `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 6 month',
      id: `${dayjs().subtract(6, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 12 Months',
      id: `${dayjs().subtract(12, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
  ];
  customDatatableSettings: any = {};
  currentPage = 1;
  itemsPerPage = 10;
  timeout: any = null;
  searchText: any = '';
  tokensData: any = [];
  searchData = '';
  queryParams: any = {};
  sort: any = {};
  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;

  constructor(
    private adminStore: Store<AdminState>,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.filter = this.formBuilder.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      monthRange: [`${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`, [Validators.required]],
      types: ['podcast', [Validators.required]]
    })
    this.subscribeToStore()

    this.activeRoute.queryParams.subscribe(query => {
      this.queryParams = {
        page: parseInt(query.page || 1),
        limit: 10,
        search: query.search || '',
        sort: query.sort ||  {field: 'streams', dir: "desc"},
        monthRange: query.monthRange || `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`,
        types: query.types || 'podcast'
      };
      this.searchData = query.search || ''
      this.sort = query.sort ? JSON.parse(query.sort) : {}
      this.callApi();
    });
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      withPage: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['artist_name', 'title', 'streams', 'email', 'createdAt'],
      order: [[2, 'desc']],
      searching: false,
    }
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getArtistStreams))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(distributeTokens => {
        if (distributeTokens) {
          this.tokensData = distributeTokens.data;
          if (this.customTableComponent?.ajaxCallback) {
            this.customTableComponent?.ajaxCallback({
              recordsTotal: distributeTokens.count.total,
              recordsFiltered: distributeTokens.count.total,
              data: []
            })
            setTimeout(() => {
              this.customTableComponent?.reAdjustColumnsInTable();
            }, 500);
          }
        } else {
          this.tokensData = [];
        }
      })

    let formattedDate = this.formatDateRange(this.filter.value.monthRange);
    this.adminStore.pipe(select(getArtistStreamsDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if(success) {
        const blob = new Blob([success.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.filter.value.types}-streams-${formattedDate}.csv`;
        link.click();
        this.toastr.success('Artist Stream CSV successfully download')
        }
      })
  }

  formatDateRange = (dateRange:any) => {
    return dateRange.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$3-$2");
  }

  getAllWalletTransaction = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'streams',
    filterType: 'desc'
  }) => {
    if (this.sort.field === order.filterBy) {
      this.sort = {field: order.filterBy, dir: order.filterType};
    } else {
      this.sort = {field: order.filterBy, dir: order.filterType};
    }
    this.router.navigate(['/artist-streams'], {
        queryParams: {
          page,
          limit: perPage,
          search,
          ...order,
          date: this.filter.value.monthRange,
          type: this.filter.value.types,
          sort: JSON.stringify(this.sort),
          otherType: true
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  filterMonthRangeData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.router.navigate(['/artist-streams'], {
        queryParams: {
          ...this.queryParams,
          date: this.filter.value.monthRange,
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  filterStatusData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    console.log("***195", this.filter.value);
    this.router.navigate(['/artist-streams'], {
        queryParams: {
          ...this.queryParams,
          page:this.currentPage,
          types: this.filter.value.types,
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  search(event: any) {
    if (event) {
      let walletSearch = event.target.value;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        walletSearch = walletSearch.trim();
        this.searchText = walletSearch;
        this.router.navigate(['/artist-streams'], {
            queryParams: {
              ...this.queryParams,
              search: walletSearch
            },
            queryParamsHandling: 'merge'
          }
        );
      }, 1500);
    }
  }

  downloadCsv() {
    this.adminStore.dispatch(ArtistStreamsDetails({
      params: {
        date: this.queryParams.monthRange,
        type: this.queryParams.types,
        otherType: true
      }
    }))
  }


  callApi() {
    this.adminStore.dispatch(ArtistStreamList({
      params: {
        page: this.queryParams.page,
        limit: this.queryParams.limit,
        search: this.queryParams.search,
        date: this.queryParams.monthRange,
        type: this.queryParams.types,
        sort: this.queryParams.sort,
        otherType: true
      }
    }))
  }

  getCurrentUser(event: any) {
    this.currentUser = event;
  }

  redirectBack() {
    history.back();
  }
}
