import {Component, OnDestroy, OnInit} from '@angular/core';
import {getLoggedInUser} from "@frontend/auth-store";
import {AdminState, getAdminDashboard, GetAdminDashboard, ResetAdminState} from "@frontend/admin-store";
import {select, Store } from '@ngrx/store';
import {Subject, takeUntil } from 'rxjs';
import * as dayjs from "dayjs";

@Component({
  selector: 'frontend-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  unsubscriber = new Subject();
  currentUser: any;
  dashboard: any;
  month: any[] = [
    {
      name: 'Current Week',
      id: `${dayjs().startOf('week').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}/CW`
    },
    {
      name: 'Current Month',
      id: `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}/CM`
    },
    {
      name: 'Current Year',
      id: `${dayjs().startOf('year').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}/CY`
    },
    {
      name: 'Last Week',
      id: `${dayjs().startOf('week').subtract(7, 'days').format('YYYY-MM-DD')}/${dayjs().endOf('week').subtract(7, 'day').format('YYYY-MM-DD')}/LW`
    },
    {
      name: 'Last Month',
      id: `${dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')}/${dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}/LM`
    },
    {
      name: 'Last Year',
      id: `${dayjs().startOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/${dayjs().endOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/LY`
    },
  ];
  monthRange: any;
  dates: any = [];
  range: any;
  chartData: any[] = [];
  monthValues: number[];
  monthKeys: string[];

  constructor(
    private adminStore: Store<AdminState>
  ) {
    this.monthRange = `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}/CM`;
    this.adminStore.dispatch(ResetAdminState({params: {dashboard: null, success: ''}}));
    this.subscribeToStore()
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.currentUser = user;
        }
      })

    this.adminStore.pipe(select(getAdminDashboard))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(dashboard => {
        if (dashboard) {
          this.dashboard = dashboard;
          this.chartData = [...dashboard.graphData];
          this.getCountsByMonth(this.chartData);
        }
      })
  }

  ngOnInit(): void {
    setTimeout(() => {
     this.getDashBoardData(this.monthRange)
    }, 0);
    const date = this.monthRange;
    this.getDates(date)
  }

  getDashBoardData(monthRange: any) {
    this.adminStore.dispatch(GetAdminDashboard({
      params: {
        monthRange: monthRange
      }}));
  }

  getDates(date: any) {
    this.dates = [];
    let [start, end, range] = date.split('/');
    start = dayjs(start);
    end = dayjs(end);
    this.range = range;

    if (this.range == 'CY' || this.range == 'LY') {
      while (start.isBefore(end)) {
        this.dates.push(start.format("MM-YYYY"));
        start = start.add(1, "month");
      }
    } else {
      this.dates.push(start.format('D MMM'))
      while (start.diff(end) < 0) {
        start = start.add(1, "days");
        this.dates.push(dayjs(start.clone().toDate()).format('D MMM'));
      }
      this.dates.push(end.format('D MMM'))
    }
  }

  getCountsByMonth(utcData: any) {
    const countsByMonth: { [key: string]: number } = {};

    for (let month = 0; month < 12; month++) {
      const monthName: string = new Date(2000, month).toLocaleDateString(
        'en-US',
        { month: 'short' }
      );
      countsByMonth[monthName] = 0;
    }

    utcData.forEach((date: any) => {
      const dateObj = new Date(date.createdAt);
      const monthName = dateObj.toLocaleDateString('en-US', { month: 'short' });
      countsByMonth[monthName]++;
    });
    this.monthKeys = Object.keys(countsByMonth);
    this.monthValues = Object.values(countsByMonth);
    return countsByMonth;
  }

  filterData(event: any) {
    this.getDashBoardData(event.value || this.monthRange)
    this.getDates(event.value || this.monthRange)
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
