<div class="">
  <div class="main_wrapper">
    <frontend-verify-header-admin (eventCurrentUser)="getCurrentUser($event)"></frontend-verify-header-admin>
    <frontend-side-bar-admin></frontend-side-bar-admin>
    <section class="main_content" >
      <div class="main_sec">
        <div class="sec_content">
          <div class="distribute-token position-relative">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 p-0">
                  <div class="back_btn" (click)="redirectBack()">
                    <a><img src="assets/images/back_ic.svg" />{{ 'back' | translate }}</a>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <h1 class="fs-32 title"> {{'Artist Streams' | translate}}</h1>
                    <div class='btn-verify' (click)="downloadCsv()">{{'Download streams csv' | translate}}</div>
                  </div>
                  <div class="tab-content mt-3">
                    <div class="tab-pane fade show active" id="payout" role="tabpanel" aria-labelledby="payout-tab">
                      <div class="distribute-token-list">
                        <div class="container-fluid">
                          <form [formGroup]="filter">
                            <div class="row my-2 my-md-4">
                              <div class="col-md-6 px-0 px-md-2 mb-1 mb-md-0">
                                <div class="form-group">
                                  <div class="left-header d-md-block">
                                    <div class="form-group mb-0">
                                      <div class="position-relative">
                                        <img src="assets/images/search_tab.png" class="search-icon status-search" alt="" />
                                        <input type="search" class="form-control br-16 search-input"
                                               placeholder="{{'search artist name' | translate}}"
                                               (keyup)="search($event)">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="px-0 px-md-2 mb-1 mb-md-0 col-md-3">
                                <div class="form-group">
                                  <label class="form-label fs-12 m-0">{{'date_range' | translate}}</label>
                                  <frontend-custom-select
                                    class="filter-wallet-data"
                                    [searchable]="true"
                                    [placeholder]="('select_date' | translate)"
                                    [selectOptions]="month"
                                    selectedValue="monthRange"
                                    (optionSelected)="filterMonthRangeData()"
                                    [formGroup]="filter">
                                  </frontend-custom-select>
                                </div>
                              </div>
                              <div class="px-0 px-md-2 mb-1 mb-md-0 col-md-3">
                                <div class="form-group">
                                  <label class="form-label fs-12 m-0">{{'types' | translate}}</label>
                                  <frontend-custom-select
                                    class="filter-wallet-data"
                                    [searchable]="true"
                                    [placeholder]="('select_type' | translate)"
                                    [selectOptions]="types"
                                    selectedValue="types"
                                    (optionSelected)="filterStatusData()"
                                    [formGroup]="filter">
                                  </frontend-custom-select>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div class="container-fluid">
                          <div class="row">
                            <div class="finance-table px-0 mb-4">
                              <div class="custom-table finances-table">
                                <frontend-custom-table
                                  #customTableComponent
                                  [customOptions]="customDatatableSettings"
                                  [tableHead]="tableHead"
                                  [ajaxFunction]="getAllWalletTransaction"
                                  [tableBody]="tableBody">
                                </frontend-custom-table>
                                <ng-template #tableHead>
                                  <thead>
                                  <tr>
                                    <td>
                                      <p
                                        class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                        {{'Artist Name' | translate}}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                        {{'Title' | translate}}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                        {{'Streams' | translate}}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                        {{'Email' | translate}}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                        {{'createdAt' | translate}}
                                      </p>
                                    </td>
<!--                                    <td></td>-->
                                  </tr>
                                  </thead>
                                </ng-template>
                                <ng-template #tableBody>
                                  <tbody *ngIf="tokensData.length <= 0">
                                  <tr>
                                    <td colspan="4">{{'no_data_found' | translate}}</td>
                                  </tr>
                                  </tbody>
                                  <tbody *ngIf="tokensData.length > 0">
                                  <tr  *ngFor="let tokenData of tokensData">
                                    <td>
                                      <p class="mb-0 fs-16 store-content-details">
                                        {{tokenData?.artist_name}}
                                      </p>
                                    </td>
                                    <td tooltip="{{tokenData?.title}}">
                                      <p class="mb-0 fs-16 store-content-details text-truncate">
                                        {{tokenData?.title}}
                                      </p>
                                    </td>
                                    <td>
                                      <p class="mb-0 fs-16 store-content-details">
                                        {{tokenData?.streams}}
                                      </p>
                                    </td>
                                    <td>
                                      <p class="mb-0 fs-16 store-content-details">
                                        {{tokenData?.email}}
                                      </p>
                                    </td>
                                    <td>
                                      <p class="mb-0 fs-16 store-content-details">
                                        {{tokenData?.createdAt | date: 'dd/MM/yyyy'}}
                                      </p>
                                    </td>
                                  </tr>
                                  </tbody>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <frontend-bottom-header-admin></frontend-bottom-header-admin>
  </div>
</div>
